@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

/* styles.css */

/* General reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Press Start 2P', cursive;
    /* Vaporwave aesthetic font */
    background: url('./aesthetic.gif') no-repeat center center fixed;
    /* Assuming the GIF is in the public folder */
    background-size: cover;
    color: #fff;
    /* Change text color to white for better contrast */
    padding: 20px;
    text-shadow: 2px 2px 4px #000000;
    /* Text shadow for all text */
}

.container {
    background: rgba(0, 0, 0, 0.4);
    /* Lighter semi-transparent black background */
    border-radius: 10px;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

header {
    text-align: center;
    margin-bottom: 20px;
}

h1 {
    font-size: 2rem;
    color: #ffff00;
    /* Bright yellow */
}

/* Countdown Timer */
.countdown {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    /* Allow wrapping */
}

.card {
    background-color: rgba(255, 105, 180, 0.8);
    /* Semi-transparent pink */
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.5rem;
    /* Adjust font size */
    text-align: center;
    min-width: 50px;
    flex: 1 1 50px;
    /* Allow cards to shrink and grow */
    white-space: pre-wrap;
    /* Ensure words break only after whitespace */
}

.card:last-child {
    margin-right: 0;
}

/* Event Details */
.event-details {
    text-align: center;
    margin-bottom: 20px;
}

.event-details h2 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #00ff00;
    /* Suitable green */
}

.event-details p {
    font-size: 1rem;
    margin: 5px 0;
}

.event-details p.date {
    color: #ff69b4;
    /* Pink */
}

.event-details p.time {
    color: #00ffff;
    /* Cyan */
}

.event-details p.location {
    color: #ffff00;
    /* Yellow */
}

.location-button {
    background-color: #00ffff;
    /* Cyan */
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    font-family: 'Press Start 2P', cursive;
    /* Ensure button text has the same font */
    text-shadow: 2px 2px 4px #000000;
    /* Text shadow for the button */
}

.location-button:hover {
    background-color: #00ced1;
    /* Darker cyan */
}

.qr-button {
    background-color: #FFFF00;
    /* Yellow */
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    font-family: 'Press Start 2P', cursive;
    /* Ensure button text has the same font */
    text-shadow: 2px 2px 4px #000000;
    /* Text shadow for the button */
}

.qr-button:hover {
    background-color: #FFD700;
    /* Gold */
}

.spotify-button {
    background-color: #1DB954;
    /* Spotify green */
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    font-family: 'Press Start 2P', cursive;
    /* Ensure button text has the same font */
    text-shadow: 2px 2px 4px #000000;
    /* Text shadow for the button */
}

.spotify-button:hover {
    background-color: #1AA34A;
    /* Darker Spotify green */
}

/* FAQ Section */
.faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    /* Semi-transparent black */
    padding: 20px;
    border-radius: 10px;
}

.faq h2 {
    font-size: 1.3rem;
    margin-bottom: 20px;
    /* Added more space */
    color: #ff69b4;
    /* Pink */
}

.faq div {
    margin-bottom: 20px;
    /* Added more space */
}

.faq p.question {
    font-size: 1.1rem;
    color: #ffff00;
    /* Bright yellow */
    margin-bottom: 10px;
}

.faq p.answer {
    font-size: 1rem;
    color: #00ffff;
    /* Cyan */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .countdown {
        gap: 5px;
        /* Reduce gap on mobile */
    }

    .card {
        font-size: 0.5rem;
        /* Reduce font size on mobile */
        padding: 5px;
        /* Reduce padding on mobile */
    }
}

@media (min-width: 768px) {
    .countdown {
        justify-content: center;
    }

    .card {
        margin: 0 10px;
    }

    header {
        margin-bottom: 40px;
    }

    .event-details {
        margin-bottom: 40px;
    }

    .faq h2 {
        font-size: 1.5rem;
    }

    .faq p {
        font-size: 1.1rem;
    }
}